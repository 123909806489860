import React from 'react'
import service2 from '../websiteImages/ProfessionalServices.svg'

const ServicesProfessional = () => {

    return (
        <div className='w-full h-full flex flex-row bg-white py-16 px-4' id='services/Professional'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
                <img className='md:w-[500px] w-[300px] mx-auto my-auto' loading='lazy' src={service2} alt='/' />
                <div className='flex flex-col justify-center'>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 md:mb-10 md:mt-0 mt-5 mb-5'>Professional Services</h1>
                    <p className='md:text-xl sm:text-lg text:base md:mt-10 my-5'>
                        We’re a valuable resource when it comes to planning, building, implementing and
                        even managing your enterprise solution. Our expertise is earned in building high
                        speed protocols with high availability environments.
                    </p>


                </div>

            </div>
        </div>
    )
}


export default ServicesProfessional