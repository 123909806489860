import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import homeSlide1 from '../websiteImages/Landing_screen_images1.jpeg';
import homeSlide2 from '../websiteImages/Landing_screen_images2.jpeg';
import homeSlide3 from '../websiteImages/Landing_screen_images3.jpeg';
import homeSlide4 from '../websiteImages/Landing_screen_images4.jpeg';
import homeSlide5 from '../websiteImages/Landing_screen_images5.jpeg'; // New import statement

const Home = () => {
    const slides = [
        { url: homeSlide1, header: "NUCLEAR IS A RISKY THREAT", content: "Cybersecurity isn’t easy, but it comes down to three basic principles – protect, detect, and respond." },
        { url: homeSlide2, header: "LIFE IS DIGITAL", content: "Critical infrastructure plays an important role in digital life. Guard your privacy—and your peace of mind." },
        { url: homeSlide3, header: "SMART CITY, SMART LIFE", content: "In a world that’s built on technology, nothing is more important than security." },
        { url: homeSlide4, header: "PROTECT YOUR DEFENSE", content: "Putting your best foot forward and taking a proactive stance against cybersecurity threats is the best defense." },
        { url: homeSlide5, header: "OIL DRIVES ECONOMIES", content: "Oil & natural gas are critical assets of an economy. Cybersecurity is not just an IT issue. It’s a business issue." }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide() 
        }, 3000);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    return (
	            <div className=' w-screen h-screen m-auto relative group'>
            <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                className='w-full h-full bg-center bg-cover duration-500'>
                    <div className='lg:w-[35%] md:w-[50%] w-full h-full bg-neutral-700 bg-opacity-40  flex flex-col items-center'>
                        <div className='w-full text-center font-bold md:text-5xl sm:text-4xl text-3xl md:mt-[5rem] mt-[3rem] drop-shadow-lg text-white'>
                            Next Generation Firewall "Data Diode"
                            <div className='font-bold md:text-xl sm:text-lg text-base drop-shadow-lg md:mt-10 text-white p-5'>
                                An Unhackable Cybersecurity Solution for Enterprise Resilience.
                            </div>

                            <a href='/cases'><button className='rounded-xl border-2 bg-[#2e51a5] md:p-4 sm:p-3 p-2
                            hover:border-[#2e51a5] hover:text-[#2e51a5] hover:bg-white
                            md:mt-10 text-white font-bold md:text-xl sm:text-lg text-base'>
                                Case Studies
                            </button></a>
                        </div>

                    </div>
            </div>
	                <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>

            <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>

            <div className='flex top-4 justify-center py-2'>
                {slides.map((slide, slideIndex) => (
                    <div
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                        className='text-2xl cursor-pointer'
                    >
                        <RxDotFilled />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;

