import React from 'react';
import service1 from '../websiteImages/On-Site-Installation.png'

const ServicesOnSite = () => {
    return (
      <div className='flex flex-col justify-center items-center' id='services'>
      <h1 className='md:text-5xl sm:text-4xl text-3xl font-bold my-3'>Services</h1>
        <div className='w-full h-full flex flex-row item-center bg-white py-16 px-4' id='services/OnSite'>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
          <img className='md:w-[500px] w-[300px] mx-auto my-auto' loading='lazy' src={service1} alt='/' />
            <div className='flex flex-col justify-center'>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 md:mb-10 md:mt-0 mt-5 mb-5'>On-site Installation Services</h1>
              <p className='md:text-xl sm:text-lg text:base md:mt-10 my-5'>
              Our experts works closely with your local team to meet your deployment time and 
            conduct training to maintain operation and configuration of the products
              </p>
              

            </div>
            
          </div>
        </div>
      </div>  
      )
}

export default ServicesOnSite;