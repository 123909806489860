import React from 'react'
import joinUs from '../websiteImages/JoinTeamImage.jpg'

const Careers = () => {
    const bgImage = [{ url: joinUs }];
  return (
        
    <div className='h-screen w-screen m-auto bg-center relative group' id='careers'>
        <div style={{ backgroundImage: `url(${bgImage[0].url})` }}
            className='w-full h-full bg-cover duration-500 flex flex-row-reverse'>
                <div className=' w-full h-full bg-neutral-700 bg-opacity-40  flex flex-col'>
                    <div className='absolute w-full h-full text-center font-bold md:text-5xl inset-x-0 top-0
                    sm:text-4xl text-2xl mt-[2rem] drop-shadow-lg text-white'>
                        Careers

                        <div className='absolute flex-grow align-text-bottom font-bold md:text-xl sm:text-lg text-base 
                        drop-shadow-lg text-white p-16 inset-x-0 bottom-0'>
                            To join our team contact us.
                        </div>
   
                    </div>
                    
                </div>
        </div>

    </div>
  )
}

export default Careers