import React from 'react'
import contact from '../websiteImages/talktous.png'

import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_00t18mx";
const TEMPLATE_ID = "template_1blno1i";
const USER_ID = "HPlMfNs_VZdQrecTj";

const Contacts = () => {

  const handleOnSubmit = (e) => {

    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully',
          confirmButtonColor: '#2e51a5'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
          confirmButtonColor: '#2e51a5'
        })
      });
    e.target.reset()
  };

  return (
    <div className='w-full h-full flex flex-row items-center justify-between 
    bg-white py-16 px-4' id='contact'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 lg:gap-[10rem] gap-20'>
        <img className='md:w-[500px] md:block hidden mx-auto my-auto' loading='lazy' src={contact} alt='contact' />
        <div className='rounded-xl shadow-lg p-8 '>
          <h1 className='text-center md:text-4xl sm:text-3xl text-2xl font-bold py-2 md:mb-10 md:mt-0 
          mt-10 mb-5'>
            Contact Us
          </h1>
          <form onSubmit={handleOnSubmit} className='flex flex-col space-y-4'>
            <div>
              <label for="" className='text-sm'>Name</label>

              <input type='text' name="from_name" placeholder='Full name' className='ring-1 ring-gray-300 w-full 
              rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-blue-800 mt-2'></input>
            </div>

            <div>
              <label for="" className='text-sm'>Email</label>

              <input type='email' name="user_email" placeholder='Email address' className='ring-1 ring-gray-300 
              w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-blue-800 mt-2'></input>
            </div>


            <div class="form-check">
                  <input class="form-check-input" type="radio" name="type" id="send_message" value="message"></input>
                  <label class="form-check-label" for="send_message">
                  &nbsp;Send a message
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="type" id="quote" value="quotation request"></input>
                  <label class="form-check-label" for="quote">
                    &nbsp; Request a quote
                  </label>
                </div>


            <div>
              <label for="" className='text-sm'>Message</label>

              <textarea type='text' name='message' placeholder='Your Message' rows={6} className='ring-1 
              ring-gray-300 w-full rounded-md px-4 py-2 mt-2 focus:ring-2 focus:ring-blue-800 
              outline-none'></textarea>
            </div>
            <button type='submit' className='inline-block self-end rounded-xl border-2 bg-[#2e51a5] 
            md:px-4 md:py-3 sm:px-3 sm:py-2 px-2 py-1 mt-20 text-white font-semibold md:text-base text-sm uppercase
            hover:border-[#2e51a5] hover:text-[#2e51a5] hover:bg-white'>
              Send Message
            </button>
          </form>



        </div>

      </div>
    </div>
  )
}

export default Contacts