import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home"
import About from "./components/About";
import ProductsOCRA from "./components/ProductsOCRA";
import ProductsMaximus from "./components/ProductsMaximus";
import ServicesOnSite from "./components/ServicesOnSite";
import ServicesProfessional from "./components/ServicesProfessional";
import Careers from "./components/Careers";
import Partners from "./components/Partners";
import Contact from "./components/Contacts";
import Footer from "./components/Footer";
import Route from "./components/Route";

import Cases from "./pages/Cases";
import TermsUse from "./pages/TermsUse";
import Legal from "./pages/Legal";

function App() {
  return (
    <div>
      <Navbar />
      <Route path="/">
        <Home />
        <About />
        <ProductsOCRA />
        <ProductsMaximus />
        <ServicesOnSite />
        <ServicesProfessional />
        <Careers />
        <Partners />
        <Contact />
      </Route>
      <Route path="/cases">
        <Cases />
      </Route>
      <Route path="/termsofuse" exact>
        <TermsUse />
      </Route>
      <Route path="/legalTerms">
        <Legal />
      </Route>
      <Footer />
    </div>
  );
}

export default App;
