import React from 'react'

const TermsUse = () => {
    return (
        <div className='w-full py-[10rem] px-4 bg-white'>
            <h1 className='flex justify-center items-center text-3xl font-bold py-8'>Terms of Use</h1>
        </div>
      );
}

export default TermsUse