import React from 'react'
import MAXIMUS from '../websiteImages/Product_AG-MAXIMUS-10G.png'
import datasheet from '../datasheets/Datasheet_AG-MAXIMUS-10G.pdf'


const ProductsMaximus = () => {
    return (
        <div className='w-full h-full flex flex-row bg-white py-16 px-4' id='products/maximus'>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
          <img className='md:w-[500px] w-[300px] mx-auto my-auto' loading='lazy' src={MAXIMUS} alt='/' />
            <div className='flex flex-col justify-center'>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 md:mb-10 my-3'>AG-MAXIMUS-10</h1>
              <p className='md:text-xl sm:text-lg text:base md:mt-10 my-3'>
              AG-MAXIMUS-10G is one-way data transfer for most widely used protocols. 
            Designed for all type of industries and government organizations to ensure the high level of 
            security. It is capable to transfer the data from 25 Mpbs to 10 Gbps.
              </p>
              <div className='flex md:flex-row flex-col md:items-stetch items-start gap-5'>
              <a href={datasheet} download='MaximusDatasheet' target='_blank'><button className='w-52 rounded-xl border-2 bg-[#2e51a5] 
              hover:border-blue-950 hover:border-[3px] hover:font-semibold hover:bg-white hover:text-blue-950
              md:p-4 sm:p-3 p-2 md:mt-10 mt-5 text-white md:text-lg text-base'>
                    Download Datasheet
                </button></a>

                <a href='/#contact'><button className='lg:mr-[3rem] w-52 rounded-xl border-[3px] font-semibold border-[#2e51a5]
                hover:border-white hover:bg-blue-950 hover:text-white hover:font-normal
                bg-white md:p-4 sm:p-3 p-2 md:mt-10 text-[#2e51a5] md:text-lg text-base'>
                    Request Quote
                </button></a>
                </div>
            </div>
            
          </div>
        </div>
      )
}

export default ProductsMaximus