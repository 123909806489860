import React from 'react'
import Case_DnI from '../usecases/Defence_DataDiode_Usecase.pdf'
import Case_OnG from '../usecases/OilGas_DataDiode_usecase.pdf'
import Case_Bank from '../usecases/BanksATM_DataDiode_usecase.pdf'
import Case_Transport from '../usecases/MetroTrain_DataDiode_Usecase.pdf'
import Case_DefenceMachines from '../usecases/DefenceMachines_usecases.pdf'
import Case_Nuclear from '../usecases/NuclearPowerPlant_Usecase.pdf'

const Cases = () => {
  return (
    <div className='w-full py-[10rem] px-4 bg-white'>
        <h1 className='flex justify-center items-center text-3xl font-bold py-8'>Case Studies</h1>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8'>
          <div className="bg-[url('websiteImages/useCaseDnI.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Defence / Intelligence</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Securing data sharing between defence & intelligence offices</p>
              </div>
              <a href={Case_DnI} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>
          <div className="bg-[url('websiteImages/useCaseOnG.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Oil and Gas</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Oil & gas company one way data transfer to head office</p>
              </div>
              <a href={Case_OnG} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>
          
          <div className="bg-[url('websiteImages/useCaseBank.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Banking</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Banks secure ATM data at central databases</p>
              </div>
              <a href={Case_Bank} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>

          <div className="bg-[url('websiteImages/useCaseTransport.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Transportation</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Metro rail secures remote monitoring of railcars and tracks</p>
              </div>
              <a href={Case_Transport} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>

          <div className="bg-[url('websiteImages/useCaseDefence.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Defence</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Secure transfer of defence machines surveillance data</p>
              </div>
              <a href={Case_DefenceMachines} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>

          <div className="bg-[url('websiteImages/useCaseNuclear.png')] bg-center bg-cover w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 text-white">
            <div className= 'bg-blue-950 bg-opacity-40 rounded-lg bg-center min-w-fit -m-4'>
              <h2 className='text-2xl font-bold text-center py-8'>Nuclear Power</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Securing nuclear power facilities</p>
              </div>
              <a href={Case_Nuclear} download={false} target='_blank' className='flex justify-center items-center'><button className='bg-blue-950 text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Know more</button></a>
          </div>
          </div>
      </div>
    </div>
  );
}

export default Cases