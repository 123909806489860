import React from 'react'
import daemon from '../websiteImages/Partners_Daemon.png'
import fuzzy from '../websiteImages/logo.png'

const Partners = () => {
    return (
        <div className='w-full h-50 flex flex-col items-center px-20 mt-20 bg-neutral-400' id='partners'>
          
            <h1 className='md:text-4xl sm:text-3xl text-white text-center text-2xl font-bold mb-5'>
                Our Partners
            </h1>
      
          <div className='flex md:flex-row md:justify-between flex-col items-center mx-auto  lg:gap-[10rem] gap-10'>
            <img className='w-[15rem] mx-auto px-4 pb-4 my-auto ' loading='lazy' src={daemon} alt='Daemon' />
            
            
          </div>
        </div>
      )
}

export default Partners
