import React, { useState } from 'react'
import Logo from '../websiteImages/Logo_Aliensguard_Transparent.png'
import { Fade as Hamburger } from 'hamburger-react'

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  const [heading, setHeading] = useState("");
    const links = [
        {
             name: 'Products', link: "./#products", submenu: true, sublink: [
                { name: 'AG-OCRA-1G', link: "./#products/ocra" },
                { name: 'AG-MAXIMUS-10G', link: "./#products/maximus" },
            ]
        },
        {
            name: 'Services', link:"/#services", submenu: true, sublink: [
                { name: 'On-Site Installation Services', link: "/#services/OnSite" },
                { name: 'Professional Services', link: "/#services/Professional" },
            ]
        }];
  
  return (
    <>
    <nav className='bg-blue-950'>
      <div className= 'w-full flex flex-column justify-between items-center sm:h-16 h-12  mx-auto px-4'>
        <div className='text-white'>
          <a href='/'><img src={Logo} alt='Aliens Guard' className='md:cursor-pointer md:w-[8rem] w-[6rem] ml-10' /></a>
          
        </div>

        <div className='md:hidden text-white'>
          <Hamburger rounded toggled={isOpen} toggle={setOpen} />
        </div>

        <ul className='md:flex hidden text-neutral-400 lg:text-base text-sm font-medium lg:mr-8 mr-0 p-4 gap-7 z-10'>
          <li className='hover:font-bold uppercase'><a href='/'>Home </a></li>
          <li className='hover:font-bold uppercase'><a href='/#company'> Company </a></li>
          {
            links.map(link => {
                return (
                    <div className='group'>
                        <h1 onClick={() => {
                            heading !== link.name ? setHeading(link.name) : setHeading("");
                        }} className='md:cursor-pointer hover:font-bold md:ml-0 ml-10 md:p-0 p-4 
                        uppercase' > <a href={link.link}> {link.name}</a></h1>
                        {link.submenu && (
                            <div className='absolute top-15 bg-blue-950 p-5 -ml-5 rounded-xl 
                    hidden group-hover:md:block hover:md:block'>
                                {link.sublink.map((agLink) => (
                                    <li className='lg:cursor-pointer capitalize text-base 
                                    hover:font-bold p-2'><a href={agLink.link}>{agLink.name}</a></li>
                                ))}
                            </div>
                        )}

                        <div className={`${heading === link.name ? "md:hidden" : "hidden"}`}>
                            {link.sublink.map((agLink) => (
                                <div>
                                    <div>
                                        <h1 className="py-4 pl-20 ml-10' capitalize text-base 
                                        font-semibold md:pr-0 pr-5 flex justify-between items-center">
                                            <a href={agLink.link}>{agLink.name}</a>
                                        </h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                );
            })
        }          <li className='hover:font-bold uppercase' ><a href='/#careers'>Careers</a></li>
          <li className='hover:font-bold uppercase' ><a href='/#contact'>Contact Us</a></li>
        </ul>

        <ul className={`${isOpen ? "left-0" : "left-[-100%]"} md:hidden bg-blue-950 
        uppercase fixed w-full top-12 sm:top-16 md:top-20 overflow-y-auto bottom-0 duration-100
        text-neutral-400 text-xl font-medium mr-10 z-10 `}>
          <li className='p-4 ml-10 uppercase'><a href='/'>Home </a></li>
          <li className='p-4 ml-10 uppercase' onClick={() => setOpen(!isOpen)}><a href='/#company'> Company </a></li>
          {
            links.map(link => {
                return (
                    <div className='group'>
                        <h1 onClick={() => {
                            heading !== link.name ? setHeading(link.name) : setHeading("");
                        }} className='md:cursor-pointer hover:font-bold md:ml-0 ml-10 md:p-0 p-4 
                        uppercase' > <a href={link.link}> {link.name}</a></h1>
                        {link.submenu && (
                            <div className='absolute top-15 bg-blue-950 p-5 -ml-5 rounded-xl 
                    hidden group-hover:md:block hover:md:block'>
                                {link.sublink.map((agLink) => (
                                    <li className='lg:cursor-pointer capitalize text-base 
                                    hover:font-bold p-2'><a href={agLink.link}>{agLink.name}</a></li>
                                ))}
                            </div>
                        )}

                        <div className={`${heading === link.name ? "md:hidden" : "hidden"}`}>
                            {link.sublink.map((agLink) => (
                                <div>
                                    <div>
                                        <h1 className="py-4 pl-20 ml-10' capitalize text-base 
                                        font-semibold md:pr-0 pr-5 flex justify-between items-center" onClick={() => setOpen(!isOpen)}>
                                            <a href={agLink.link}>{agLink.name}</a>
                                        </h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                );
            })
        }
          <li className='p-4 ml-10 uppercase' onClick={() => setOpen(!isOpen)}><a href='/#careers'>Careers</a></li>
          <li className='p-4 ml-10 uppercase' onClick={() => setOpen(!isOpen)}><a href='/#contact'>Contact Us</a></li>
        </ul>
      </div >
    </nav>
    </>
  )
}

export default Navbar